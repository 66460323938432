import React, { useState, useEffect, useRef } from "react"
// import { trackCustomEvent } from 'gatsby-plugin-google-tagmanager';

import InputMask from "react-input-mask"
import { sendEmail } from "../../../js/helper"
import { useFormik } from "formik"
import Cookies from "js-cookie"
import { hubSpotCookieName, hubSpotContactFormId } from "../../../js/config"

import ThankYou from "../../../images/MainPage/RCHolder/ContactUs/LetterTu.svg"
import CustomSelect from "../CustomSelect";
import "./FormField.scss"

export default function FormField({
  header,
  nameLabel,
  emailLabel,
  numberLabel,
  messageLabel,
  url = "",
  form = 0,
  dataForSelect = false,
  selectValueDefault,
}) {
  const [userName, setUserName] = useState("");

  const [isLetterSent, setLetterSent] = useState(false);

  const [sentIsError, setSentIsError] = useState(false);

  const [isWaiting, setIsWaiting] = useState(false);

  // const [analyticsCategory, setAnalyticsCategory] = useState();

  const [userSelect, setUserSelect] = useState('');

  const inputRefs = {
    footerFormName: useRef(null),
    footerFormNumber: useRef(null),
    footerFormEmail: useRef(null),
    footerFormMessage: useRef(null),
  }

  const errorRef = useRef(null)

  const successRef = useRef(null)

  useEffect(() => {
    if (document.forms[0]) {
      document.forms[0].reset()
    }
    // let path = window.location.pathname.split("/")
    // if (path.includes("expertise")) {
    //   setAnalyticsCategory("form_expertise")
    // } else {
    //   setAnalyticsCategory("form_main")
    // }
  })

  // Scroll to sent form error if error is true
  useEffect(() => {
    if (sentIsError) {
      if (errorRef.current != null) {
        errorRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        })
      }
    }
  }, [sentIsError])

  // Scroll to thank you when form is succesfully sent
  useEffect(() => {
    if (isLetterSent) {
      if (successRef.current != null) {
        successRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        })
      }
    }
  }, [isLetterSent])

  const scrollIntoFieldsErrors = errors => {
    if (Object.keys(errors).length == 0) {
      return
    }

    const errorFields = Object.keys(errors)

    const firstRefWithError = inputRefs[errorFields[0]]

    if (!firstRefWithError?.current) return

    // Scroll to first known error into view
    firstRefWithError.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    })
  }

  const isBrowser = typeof window !== "undefined"
  const hutk = isBrowser ? Cookies.get(hubSpotCookieName) : null
  const pageUri = isBrowser ? window.location.href : null
  const pageName = isBrowser ? document.title : null

  const validate = values => {
    if (isWaiting) {
      return false;
    }

    const errors = {}
    const userName = values.footerFormName.trim();

    if (!userName) {
      errors.footerFormName = "Please enter your name"
    } else if (userName.length < 2) {
      errors.footerFormName = "Name must contain at least 2 characters"
    } else if (userName.length > 50) {
      errors.footerFormName = "Name must contain less then 50 characters"
    }

    if (!values.footerFormEmail) {
      errors.footerFormEmail = "Please enter your email"
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.footerFormEmail)) {
      errors.footerFormEmail = "Email is not valid"
    } else if (values.footerFormEmail.length > 50) {
      errors.footerFormEmail = "Email must contain less then 50 characters"
    }

    if (!values.footerFormNumber) {
      errors.footerFormNumber = "Please enter your number"
    } else if (values.footerFormNumber.length < 12) {
      errors.footerFormNumber = "Number is too short"
    } else if (values.footerFormNumber.length > 22) {
      errors.footerFormNumber = "Number is too long"
    } else if (!/^\+{0,1}\s\d{10,20}/g.test(values.footerFormNumber)) {
      errors.footerFormNumber = "Please enter valid number"
    }
    return errors
  }

  const formik = useFormik({
    initialValues: {
      footerFormName: "",
      footerFormEmail: "",
      footerFormNumber: "",
      footerFormMessage: "",
    },
    validate,
    onSubmit: async values => {
      // event.preventDefault()
      if (isWaiting) {
        return false;
      }
      setUserName(values.footerFormName)

      setIsWaiting(true);

      await sendEmail(
        {
          name: values.footerFormName,
          email: values.footerFormEmail,
          phone: values.footerFormNumber,
          requestType: userSelect,
          message: values.footerFormMessage,
          url,
          form,
        },
        null,
        hubSpotContactFormId,
        {
          hutk: hutk,
          pageUri: pageUri,
          pageName: pageName,
        },
        setLetterSent,
        setSentIsError,
      );

      setIsWaiting(false);

    },
  })

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
  } = formik

  // Removing double gaps from name entry
  const nameNotDoubleGaps = values.footerFormName.replace(/  +/g, " ")

  const getValueSelect = selected => {
    setUserSelect(selected)
  }

  const handleSubmitAndScroll = () => {
    const errors = validate(values)
    if (errors) {
      scrollIntoFieldsErrors(errors)
    }
    handleSubmit()
  }

  return (
    <div className="footer-form-container">
      {!isLetterSent ? (
        <>
          <h2
            className="footer-form-header"
            style={
              isLetterSent
                ? { visibility: "hidden" }
                : { visibility: "visible" }
            }
          >
            {header}
          </h2>
          <form
            className="footer-form"
            style={
              isLetterSent
                ? { transform: "scale(0.5)", visibility: "hidden" }
                : { visibility: "visible" }
            }
          >
            {sentIsError && (
              <div className="footer-form-send-error" ref={errorRef}>
                Something went wrong, please try again later!
              </div>
            )}
            <div className="footer-input-row">
              <div
                className={
                  errors.footerFormName && touched.footerFormName
                    ? "input-container name-container error"
                    : "input-container name-container"
                }
              >
                <input
                  type="text"
                  id="footerFormName"
                  name="footerFormName"
                  className={values.footerFormName ? "active" : null}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={nameNotDoubleGaps}
                  disabled={isWaiting}
                  ref={inputRefs.footerFormName}
                />
                <label
                  className={
                    values.footerFormName
                      ? "input-label name-label active"
                      : "input-label name-label"
                  }
                  htmlFor="footerFormName"
                >
                  {nameLabel}
                </label>
                {errors.footerFormName && touched.footerFormName ? (
                  <div className="footer-form-error">
                    {errors.footerFormName}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="footer-input-row">
              <div
                className={
                  errors.footerFormEmail && touched.footerFormEmail
                    ? "input-container email-container error"
                    : "input-container email-container"
                }
              >
                <input
                  type="text"
                  id="footerFormEmail"
                  name="footerFormEmail"
                  className={values.footerFormEmail ? "active" : null}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.footerFormEmail}
                  disabled={isWaiting}
                  ref={inputRefs.footerFormEmail}
                />
                <label
                  className={
                    values.footerFormEmail
                      ? "input-label email-label active"
                      : "input-label email-label"
                  }
                  htmlFor="footerFormEmail"
                >
                  {emailLabel}
                </label>
                {errors.footerFormEmail && touched.footerFormEmail ? (
                  <div className="footer-form-error">
                    {errors.footerFormEmail}
                  </div>
                ) : null}
              </div>

              <div
                className={
                  errors.footerFormNumber && touched.footerFormNumber
                    ? "input-container number-container error"
                    : "input-container number-container"
                }
              >
                <InputMask
                  type="text"
                  id="footerFormNumber"
                  name="footerFormNumber"
                  className={values.footerFormNumber ? "active" : null}
                  mask="+ 999999999999999999999"
                  maskChar={null}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.footerFormNumber}
                  disabled={isWaiting}
                  ref={inputRefs.footerFormNumber}
                />
                <label
                  className={
                    values.footerFormNumber
                      ? "input-label number-label active"
                      : "input-label number-label"
                  }
                  htmlFor="footerFormNumber"
                >
                  {numberLabel}
                </label>
                {errors.footerFormNumber && touched.footerFormNumber ? (
                  <div className="footer-form-error">
                    {errors.footerFormNumber}
                  </div>
                ) : null}
              </div>
            </div>

            {dataForSelect && (
              <div className="footer-input-row">
                <CustomSelect
                  dataForSelect={dataForSelect}
                  getValueSelect={getValueSelect}
                  selectValueDefault={selectValueDefault}
                  disabled={isWaiting}
                />
              </div>
            )}

            <div className="footer-input-row">
              <div className="input-container textarea-container">
                <textarea
                  id="footerFormMessage"
                  name="footerFormMessage"
                  onChange={handleChange}
                  value={values.footerFormMessage}
                  disabled={isWaiting}
                  ref={inputRefs.footerFormMessage}
                />
                <label
                  className={
                    values.footerFormMessage
                      ? "input-label textarea-label active"
                      : "input-label textarea-label"
                  }
                  htmlFor="footerFormMessage"
                >
                  {messageLabel}
                </label>
              </div>
            </div>

            <button
              type="button"
              className="footerSubmitBtn"
              onClick={handleSubmitAndScroll}
            >
              {isWaiting ? 'Sending...' : 'Get in touch'}
            </button>
          </form>
        </>
      ) : (
        <div
          ref={successRef}
          className="form-thanks-container"
          style={
            isLetterSent ? { display: "visible" } : { visibility: "hidden" }
          }
        >
          <div className="form-thanks-text">
            <div className="form-thanks-title">
              <span>Thank you,</span>
              <span>{userName},</span>
              <span>we will get back to you soon</span>
            </div>
            <p className="form-thanks-info">
              Your Email has been sent! We appreciate you reaching out and are
              stoked about the opportunity to work together. Stay tuned and we
              will get back to you soon.
            </p>
          </div>
          <div className="form-thanks-icon">
            <img src={ThankYou} alt="Letter" />
          </div>
        </div>
      )}
    </div>
  )
}
