import React, {useEffect, useState} from 'react';
import {Box, FormControl, MenuItem, Select,} from "@material-ui/core";
import './CustomSelect.scss'

export default function CustomSelect({
                                       dataForSelect,
                                       getValueSelect,
                                       selectValueDefault = 'Estimate a project',
                                       disabled,
                                     }) {

  const [selectValue, setSelectValue] = useState(selectValueDefault);

  const handleChange = (event) => {
    setSelectValue(event.target.value);
  };

  useEffect(() => {
    getValueSelect(selectValue);
  }, [selectValue])

  return (
    <FormControl fullWidth className="my-select-container">
      <Select
        value={selectValue}
        onChange={handleChange}
        renderValue={() => selectValue}
        className="my-select"
        disabled={disabled}
      >
        {
          dataForSelect.map(option =>
            <MenuItem
              value={option.text}
              key={option.id}
              className="my-select-option"
            >
              {option.text}
            </MenuItem>
          )
        }
      </Select>
    </FormControl>
  );
}
